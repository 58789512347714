import APIClinics from '@/api/modules/clinics';

const initialState = {
  clinics: [],
  clinicsMeta: {},
  clinic: {},
  accreditations: [],
  clinicEntityAliasesByLang: [],
};

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    SET_DATA(state, { type, data }) {
      state[type] = data;
    },
  },
  actions: {
    getClinics({ commit }, params) {
      return APIClinics.getClinics(params).then((response) => {
        if (response && response.data && response.data.clinics) {
          commit('SET_DATA', { type: 'clinics', data: response.data.clinics });
          commit('SET_DATA', { type: 'clinicsMeta', data: response.data.meta });
        }
      });
    },
    getClinic({ commit }, clinicId) {
      return APIClinics.getClinic(clinicId).then((response) => {
        if (response && response.data) {
          commit('SET_DATA', { type: 'clinic', data: response.data.clinic });
        }
      });
    },
    updateClinic({ commit }, params) {
      return APIClinics.updateClinic(params);
    },
    getAccreditations({ commit }) {
      return APIClinics.getAccreditations().then((response) => {
        if (response && response.data) {
          commit('SET_DATA', { type: 'accreditations', data: response.data.accreditations });
        }
      });
    },
    getClinicEntityAliasesByLang({ commit }, params) {
      return APIClinics.getClinicEntityAliasesByLang(params).then((response) => {
        if (response && response.data) {
          commit('SET_DATA', { type: 'clinicEntityAliasesByLang', data: response.data.texts });
        }
      });
    },
    updateClinicEntityAliasesByLang({ commit }, params) {
      return APIClinics.updateClinicEntityAliasesByLang(params);
    },
  },
};
