import ConfigCmsApi from '../configs/ConfigCmsApi';
import Cookies from "js-cookie";

const getClinics = (params) => ConfigCmsApi.get('/clinics/', {
  params,
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const getClinic = (clinicId) => ConfigCmsApi.get(`/clinics/${clinicId}`, {
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const updateClinic = ({ data, clinicId }) => ConfigCmsApi.post(`/clinics/${clinicId}`, data, {
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const getAccreditations = () => ConfigCmsApi.get('/clinics/accreditations', {
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const addImages = ({ clinicId, file }) => {
  const bodyFormData = new FormData();
  bodyFormData.append('file', file);

  return ConfigCmsApi.post(`/clinics/${clinicId}/add-images`, bodyFormData, {
    headers: {
      Authorization: Cookies.get('adminToken'),
    },
  });
};
const getClinicEntityAliasesByLang = ({ clinicId, lang, entity, entityId }) => ConfigCmsApi.get(`/clinics/${clinicId}/entity-texts/${lang}/${entity}/${entityId}`, {
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const updateClinicEntityAliasesByLang = ({ clinicId, lang, entity, entityId, data }) => ConfigCmsApi.post(`/clinics/${clinicId}/entity-texts/${lang}/${entity}/${entityId}`, data, {
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const getClinicDomainTexts = ({ clinicId, lang }) => ConfigCmsApi.get(`/clinics/${clinicId}/domain-texts/${lang}`, {
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const updateClinicDomainTexts = ({ clinicId, lang, data }) => ConfigCmsApi.post(`/clinics/${clinicId}/domain-texts/${lang}`, data, {
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

export default {
  getClinics,
  getClinic,
  updateClinic,
  getAccreditations,
  addImages,
  getClinicEntityAliasesByLang,
  updateClinicEntityAliasesByLang,
  getClinicDomainTexts,
  updateClinicDomainTexts,
};
