<template>
  <div class="sidebar" v-show="toggleSidebar">
    <div class="sidebar-wrapper" id="style-3">
      <div class="logo">
        <a href="http://bookimed.com" target="_blank" class="simple-text logo-normal">
          Bookimed  <i class="tim-icons icon-heart-2"></i>
        </a>
      </div>
      <ul class="nav">
        <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->
        <slot name="links">
          <sidebar-link v-for="(link,index) in sidebarLinks"
                        :key="index"
                        :to="link.path"
                        :name="link.name"
                        :icon="link.icon">
          </sidebar-link>
        </slot>
        <li class="nav-item">
          <a :href="`https://admin.bookimed${domainType}/agencies/index`" class="nav-link" target="_blank">
            <i class="tim-icons icon-bank"></i><p>Partners</p>
          </a>
        </li>
        <li class="nav-item">
          <a :href="`https://admin.bookimed${domainType}/docs/index`" class="nav-link" target="_blank">
            <i class="tim-icons icon-paper"></i><p>Static pages</p>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  import SidebarLink from "./SidebarLink";

  export default {
    props: {
      sidebarLinks: {
        type: Array,
        default: () => []
      },
    },
    computed: {
      domainType() {
        return window.location.hostname.slice(window.location.hostname.lastIndexOf('.'));
      },
      toggleSidebar() {
        return window.innerWidth >= 991 ? true : this.$sidebar.showSidebar
      },
    },
    components: {
      SidebarLink
    },
  };
</script>
