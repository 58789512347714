import Vue from 'vue';
import Vuex from 'vuex';
import doctors from './modules/doctors';
import data from './modules/data';
import cmsApiCatalogs from './modules/cms-api-catalogs';
import clinics from './modules/clinics';
import reviews from './modules/reviews';
import ai from './modules/ai';
import chatApi from './modules/chat-api';
import offersClusters from './modules/offers-clusters';
import helpCenter from './modules/help-center';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    doctors,
    data,
    cmsApiCatalogs,
    clinics,
    reviews,
    ai,
    chatApi,
    offersClusters,
    helpCenter,
  },
  state: {
    isLoaderVisible: false,
  },
  mutations: {
    SET_DATA(state, { type, data }) {
      state[type] = data;
    },
  },
  actions: {
    setLoader({ commit }, data) {
      commit('SET_DATA', { type: 'isLoaderVisible', data });
    },
  },
});
