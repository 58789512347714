var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"navbar",class:[
        {'navbar-expand-lg': _vm.expand},
        ( _obj = {}, _obj[("navbar-" + _vm.effect)] = _vm.effect, _obj ),
        ( _obj$1 = {}, _obj$1[("bg-" + _vm.type)] = _vm.type, _obj$1 ),
        {'rounded': _vm.round}
     ]},[_c('div',{staticClass:"container"},[_vm._t("container-pre"),_vm._t("brand",function(){return [_c('a',{staticClass:"navbar-brand",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.onTitleClick.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.title)+" ")])]}),_c('navbar-toggle-button',{attrs:{"toggled":_vm.toggled,"target":_vm.contentId},nativeOn:{"click":function($event){$event.stopPropagation();_vm.toggled = !_vm.toggled}}}),_vm._t("container-after"),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeMenu),expression:"closeMenu"}],staticClass:"collapse navbar-collapse",class:{show: _vm.toggled},attrs:{"id":_vm.contentId}},[_c('div',{staticClass:"navbar-collapse-header"},[_vm._t("content-header",null,{"closeMenu":_vm.closeMenu})],2),_vm._t("default",null,{"closeMenu":_vm.closeMenu})],2)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }