import VueRouter from "vue-router";
import routes from "./routes";
import axios from "axios";
import Cookies from "js-cookie";


// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  mode: 'history',
  linkExactActiveClass: "active",
  scrollBehavior: (to) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  }
});

const checkIsAdmin = (token) => {
  const domainType = process.env.NODE_ENV === 'development' ? '.run' : window.location.hostname.slice(window.location.hostname.lastIndexOf('.'));
  return axios.get(`https://auth.bookimed${domainType}/api/v1/oauth/tokens/info?access_token=${token}`).then(response => {
    if (response.data && response.data.scopes && response.data.scopes.admin) {
      return response.data.scopes.admin.id === 'admin';
    }
    return false;
  }).catch(() => false);
};

router.beforeEach(async (to, from, next) => {
  const adminToken = Cookies.get('adminToken');
  if (to.name !== 'Login' && (window.btoa(adminToken) !== Cookies.get('vT'))) {
    const isAdmin = await checkIsAdmin(Cookies.get('adminToken'));
    if (!isAdmin) {
      return next({ name: 'Login'});
    }
  }
  next();
});

export default router;
