import APICmsCatalogs from '@/api/modules/cms-api-catalogs';

const defaultDetails = {
  country: [
    {
      "detail_name": "for_home",
      "detail_value": "0"
    },
    {
      "detail_name": "top",
      "detail_value": "0"
    },
    {
      "detail_name": "continent_id",
      "detail_value": "0"
    },
    {
      "detail_name": "price_rate",
      "detail_value": "0"
    },
    {
      "detail_name": "commission_percent",
      "detail_value": "0"
    },
    {
      "detail_name": "en_moderation",
      "detail_value": "0"
    },
    {
      "detail_name": "ru_moderation",
      "detail_value": "0"
    }
  ],
  'illness-kind': [
    { 'detail_name': 'requests_processed', 'detail_value': '0' },
    { 'detail_name': 'doctor_id', 'detail_value': '0' },
    { 'detail_name': 'editor_id_ru', 'detail_value': '0' },
    { 'detail_name': 'editor_id_en', 'detail_value': '0' },
  ],
  illness: [
    {
      'detail_name': 'parent_id',
      'detail_value': '0'
    },
  ],
  diagnostic: [
    {
      'detail_name': 'is_consultation',
      'detail_value': '0'
    },
    {
      'detail_name': 'top',
      'detail_value': '0'
    },
  ],
  operation: [
    {
      'detail_name': 'days_in_clinic',
      'detail_value': '0'
    },
    {
      'detail_name': 'days_in_country',
      'detail_value': '0'
    },
    {
      'detail_name': 'rehabilitation',
      'detail_value': '0'
    },
    {
      'detail_name': 'anesthesia',
      'detail_value': ''
    },
    {
      'detail_name': 'treatment_duration',
      'detail_value': '0'
    },
    {
      'detail_name': 'treatment_conditions',
      'detail_value': ''
    },
    {
      'detail_name': 'category_id',
      'detail_value': ''
    },
    {
      'detail_name': 'parent_id',
      'detail_value': '-1'
    },
    {
      'detail_name': 'top',
      'detail_value': '0'
    }
  ],
};
const initialState = {
  unlinkEntities: [],
  countries: [],
  countriesMeta: {},
  clinicsMedia: null,
  clinicsMediaItem: null,
  country: {
    country: {
      alias: {},
      name: {},
      name_forms: {},
      is_hide: false,
      code: '',
      country_group: null,
    },
    details: defaultDetails.country,
  },
  domains: [],
  cities: [],
  citiesMeta: {},
  city: {
    city: {
      alias: {},
      country: null,
      name: {},
      name_forms: {},
      is_hide: false,
    }
  },
  'illness-kinds': [],
  'illness-kinds-meta': [],
  'illness-kind': {
    details: defaultDetails['illness-kind'],
    'illness_kind': {
      alias: {},
      name: {},
      name_forms: {},
      is_hide: false,
    }
  },
  illnesses: [],
  illness: {
    details: defaultDetails.illness,
    illness: {
      alias: {},
      name: {},
      name_forms: {},
      is_hide: false,
    }
  },
  illnessesMeta: {},
  diagnostics: [],
  diagnosticsMeta: {},
  diagnostic: {
    details: defaultDetails.diagnostic,
    diagnostic: {
      alias: {},
      name: {},
      name_forms: {},
      is_hide: false,
      use_for_listing: false,
    }
  },
  operations: [],
  operationsMeta: {},
  operation: {
    details: defaultDetails.operation,
    operation: {
      alias: {},
      name: {},
      name_forms: {},
      is_hide: false,
      use_for_listing: false,
    }
  },
  entityTexts: [],
  entityTextsConnection: [],
  illnessesByProcedure: {},
};

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    SET_DATA(state, { type, data }) {
      state[type] = data;
    },
  },
  actions: {
    getCountries({ commit }, params) {
      return APICmsCatalogs.getCountries(params).then((response) => {
        if (response && response.data && response.data.countries) {
          commit('SET_DATA', { type: 'countries', data: response.data.countries });
          commit('SET_DATA', { type: 'countriesMeta', data: response.data.meta});
        }
      });
    },
    getCountry({ commit }, countryId) {
      return APICmsCatalogs.getCountry(countryId).then((response) => {
        if (response && response.data) {
          commit('SET_DATA', { type: 'country', data: response.data });
        }
      });
    },
    getDomains({ commit }) {
      return APICmsCatalogs.getDomains().then((response) => {
        if (response && response.data && response.data.domains) {
          commit('SET_DATA', { type: 'domains', data: response.data.domains });
        }
      });
    },
    getCities({ commit }, params) {
      return APICmsCatalogs.getCities(params).then((response) => {
        if (response && response.data && response.data.cities) {
          commit('SET_DATA', { type: 'cities', data: response.data.cities });
          commit('SET_DATA', { type: 'citiesMeta', data: response.data.meta });
        }
      });
    },
    getCity({ commit }, cityId) {
      return APICmsCatalogs.getCity(cityId).then((response) => {
        if (response && response.data) {
          commit('SET_DATA', { type: 'city', data: response.data });
        }
      });
    },
    getIllnessKinds({ commit }, params) {
      return APICmsCatalogs.getIllnessKinds(params).then((response) => {
        if (response && response.data) {
          commit('SET_DATA', { type: 'illness-kinds', data: response.data.illness_kinds });
          commit('SET_DATA', { type: 'illness-kinds-meta', data: response.data.meta });
        }
      });
    },
    getIllnesses({ commit }, params) {
      return APICmsCatalogs.getIllnesses(params).then((response) => {
        if (response && response.data) {
          commit('SET_DATA', { type: 'illnesses', data: response.data.illnesses });
          commit('SET_DATA', { type: 'illnessesMeta', data: response.data.meta });
        }
      });
    },
    getDiagnostics({ commit }, params) {
      return APICmsCatalogs.getDiagnostics(params).then((response) => {
        if (response && response.data) {
          commit('SET_DATA', { type: 'diagnostics', data: response.data.diagnostics });
          commit('SET_DATA', { type: 'diagnosticsMeta', data: response.data.meta });
        }
      });
    },
    getOperations({ commit }, params) {
      return APICmsCatalogs.getOperations(params).then((response) => {
        if (response && response.data) {
          commit('SET_DATA', { type: 'operations', data: response.data.operations });
          commit('SET_DATA', { type: 'operationsMeta', data: response.data.meta });
        }
      });
    },
    getEntity({ commit }, { entityId, type, entitiesString }) {
      return APICmsCatalogs.getEntity({ entityId, entitiesString }).then((response) => {
        if (response && response.data) {
          if (type === 'city') {
            commit('SET_DATA', { type, data: response.data });
            return;
          }
          let data = {};

          if (response.data.details && !response.data.details.length) {
            data = { ...response.data, details: defaultDetails[type] };
          } else {
            let tmpArray = []
            const details = [...response.data.details, ...defaultDetails[type]]
              .filter(item => {
                if (tmpArray.indexOf(item.detail_name) === -1) {
                  tmpArray.push(item.detail_name);
                  return true;
                }
                return false;
              });
            data = { ...response.data, details };
          }

          commit('SET_DATA', { type, data });
        }
      });
    },
    updateEntityDetails({ commit }, params) {
      return APICmsCatalogs.updateEntityDetails(params);
    },
    saveEntity({ commit }, params) {
      return APICmsCatalogs.saveEntity(params);
    },
    linkOrUnlinkConnection({ commit }, params) {
      return APICmsCatalogs.linkOrUnlinkConnection(params);
    },
    getEntityTexts({ commit }, params) {
      return APICmsCatalogs.getEntityTexts(params).then((response) => {
        if (response && response.data) {
          commit('SET_DATA', { type: 'entityTexts', data: response.data });
        }
      });
    },
    getIllnessesByProcedure({ commit }, params) {
      return APICmsCatalogs.getIllnessesByProcedure(params).then((response) => {
        if (response && response.data) {
          commit('SET_DATA', { type: 'illnessesByProcedure', data: response.data });
        }
      });
    },
    getEntityTextsConnection({ commit }, params) {
      return APICmsCatalogs.getEntityTextsConnection(params).then((response) => {
        if (response && response.data) {
          commit('SET_DATA', { type: 'entityTextsConnection', data: response.data });
        }
      });
    },
    getUnlinkEntities({ commit }) {
      return APICmsCatalogs.getUnlinkEntities().then((response) => {
        if (response && response.data) {
          commit('SET_DATA', { type: 'unlinkEntities', data: response.data.entities });
        }
      });
    },
    changeUnlinkStatus({ commit }, params) {
      return APICmsCatalogs.changeUnlinkStatus(params);
    },
    getClinicsMedia({ commit }, data) {
      return APICmsCatalogs.getClinicsMedia(data.params).then((response) => {
        if (response?.data?.items?.length) {
          commit('SET_DATA', { type: 'clinicsMedia', data: response.data.items });
        }
      });
    },
    getClinicsMediaItem({ commit }, mediaId) {
      return APICmsCatalogs.getClinicsMediaItem(mediaId).then((response) => {
        if (response?.data?.media) {
          commit('SET_DATA', { type: 'clinicsMediaItem', data: response.data.media });
        }
      });
    },
  },
};
