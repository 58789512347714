import ConfigCmsApi from "../configs/ConfigCmsApi";
import Cookies from "js-cookie";

const alias = "/faq/question-answer";

const getQuestionsList = (params) =>
  ConfigCmsApi.get(alias, {
    params,
    headers: {
      Authorization: Cookies.get("adminToken")
    }
  });

const deleteQuestion = (id) => {
  ConfigCmsApi.delete(`${alias}/${id}`, {
    headers: {
      Authorization: Cookies.get("adminToken")
    }
  });
}
  

export default {
  getQuestionsList,
  deleteQuestion,
};
