import ConfigData from '../configs/ConfigData';

const getCurrencies = () => ConfigData.get('/api/v1/currencies');
const getLanguages = () => ConfigData.get('/api/v1/languages');
const getCountriesGroup = () => ConfigData.get('/api/v1/countries-group');

export default {
  getCurrencies,
  getLanguages,
  getCountriesGroup,
};
