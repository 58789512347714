import APIHelpCenter from "@/api/modules/help-center";

const state = () => ({
  list: {
    questions: [],
    metadata: {}
  },
  headersData: {}
});

const mutations = {
  SET_DATA(state, { type, data }) {
    state[type] = data;
  }
};
const actions = {
  async getQuestionsList({ commit }, params) {
    try {
      const response = await APIHelpCenter.getQuestionsList(params);
      commit("SET_DATA", { type: "headersData", data: response.headers });
      commit("SET_DATA", {
        type: "questionsList",
        data: response.data
      });
    } catch (error) {
      commit("SET_DATA", {
        type: "list",
        data: []
      });
    }
  },

  async deleteQuestion(_, id) {
    await APIHelpCenter.deleteQuestion(id);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
