import APIChatAPI from '@/api/modules/chat-api';

const initialState = {
  agentsNames: [],
  modelsNames: [],
  agentDataByName: {},
};

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    SET_DATA(state, { type, data }) {
      state[type] = data;
    },
  },
  actions: {
    getAgentsNames({ commit }) {
      return APIChatAPI.getAgentsNames().then((response) => {
        if (response && response.data) {
          commit('SET_DATA', { type: 'agentsNames', data: response.data });
        }
      });
    },
    getModelsName({ commit }) {
      return APIChatAPI.getModelsName().then((response) => {
        if (response && response.data) {
          commit('SET_DATA', { type: 'modelsNames', data: response.data });
        }
      });
    },
    getAgentDataByName({ commit }, params) {
      return APIChatAPI.getAgentDataByName(params).then((response) => {
        if (response && response.data) {
          commit('SET_DATA', { type: 'agentDataByName', data: response.data });
        }
      });
    },
    updateAgent({ commit }, data) {
      return APIChatAPI.updateAgent(data);
    }
  },
};
