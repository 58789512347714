import ConfigOffersClusters from '../configs/ConfigOffersClusters';
import Cookies from "js-cookie";

const getAgentsNamesOffers = () => ConfigOffersClusters.get('/prompts/agents_names ', {
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const getModelsNameOffers  = () => ConfigOffersClusters.get('/prompts/models_name', {
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const getAgentDataByNameOffers = (params) => ConfigOffersClusters.get('/prompts/agent_data', {
  params,
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const updateAgentOffers = (data) => ConfigOffersClusters.post('/prompts/update_agent ', data, {
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

export default {
  getAgentsNamesOffers,
  getModelsNameOffers,
  getAgentDataByNameOffers,
  updateAgentOffers,
};
