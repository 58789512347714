import Cookies from 'js-cookie';
import APIAuth from '@/api/modules/auth';
import axios from 'axios';
const { protocol } = window.location;
const encodedData = window.location.pathname === '/' ? window.btoa(window.location.href + 'admin/doctors') : window.btoa(window.location.href);
const domainType = window.location.hostname.slice(window.location.hostname.lastIndexOf('.'));
const loginUrl = process.env.NODE_ENV !== 'development' ? `${protocol}//cms.bookimed${domainType}/auth/login?redirectUrl=${encodedData}` : `http://localhost:8080/auth/login?redirectUrl=${encodedData}`;

const microserverConfig = (instance) => {
  instance.interceptors.request.use((config) => {
    if (!Cookies.get('adminToken') && !window.location.href.includes('/auth/')) {
      window.open(loginUrl, '_self');
    }

    return config;
  });

  instance.interceptors.response.use(undefined, ({ response }) => {
    if (response && response.status === 401) {
      const originalRequest = response.config;

      return APIAuth.refreshToken().then((authResponse) => {
        if (authResponse && authResponse.data && authResponse.data.token) {
          const domain = window.location.hostname.split('.').slice(-2).join('.');
          Cookies.set('adminToken', authResponse.data.token, { path: '/', domain, expires: 1 / 3 });
          originalRequest.headers['Authorization'] = authResponse.data.token;

          return axios(originalRequest);
        } else {
          window.open(loginUrl, '_self');
        }
      }).catch(() => {
        window.open(loginUrl, '_self');
      });
    }
  });

  return instance;
};

export default microserverConfig;
