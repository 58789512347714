import APIData from '@/api/modules/data';

const initialState = {
  currencies: [],
  languages: [],
  сountriesGroup: [],
};

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    SET_DATA(state, { type, data }) {
      state[type] = data;
    },
  },
  actions: {
    getCurrencies({ commit }) {
      return APIData.getCurrencies().then((response) => {
        if (response && response.data) {
          commit('SET_DATA', { type: 'currencies', data: response.data });
        }
      });
    },
    getLanguages({ commit }) {
      return APIData.getLanguages().then((response) => {
        if (response && response.data) {
          commit('SET_DATA', { type: 'languages', data: response.data });
        }
      });
    },
    getCountriesGroup({ commit }) {
      return APIData.getCountriesGroup().then((response) => {
        if (response && response.data) {
          commit('SET_DATA', { type: 'сountriesGroup', data: response.data });
        }
      });
    },
  },
};
