import ConfigCmsApi from '../configs/ConfigCmsApi';
import ConfigDoctors from '../configs/ConfigDoctors';
import Cookies from "js-cookie";

const getDoctors = (params) => ConfigCmsApi.get('/doctors/', {
  params,
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});
const getDoctor = (doctorId) => ConfigCmsApi.get(`/doctors/${doctorId}`, {
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const getDoctorSpecialities = () => ConfigDoctors.get('/api/doctors/specialities', {
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const getDoctorDegrees = () => ConfigDoctors.get('api/doctors/degrees', {
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const updateDoctor = ({ data }) => ConfigCmsApi.put('/doctors/', data, {
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const updateDoctorsPhoto = ({ file, doctorId, method = 'POST' }) => {
  const data = new FormData();
  data.append('file', file);

  return ConfigCmsApi.request({
    url: `/doctors/${doctorId}/image`,
    method,
    data,
    headers: {
      Authorization: Cookies.get('adminToken'),
    }
  })
};

const deleteDoctorsPhoto = (doctorId) => ConfigCmsApi.request({
  url: `/doctors/${doctorId}/image`,
  method: 'DELETE',
  headers: {
    Authorization: Cookies.get('adminToken'),
  }
});

export default {
  getDoctors,
  getDoctor,
  getDoctorSpecialities,
  getDoctorDegrees,
  updateDoctor,
  updateDoctorsPhoto,
  deleteDoctorsPhoto,
};
