import APIAi from '@/api/modules/ai';

const initialState = {
  parsedClinic: {},
};

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    SET_DATA(state, { type, data }) {
      state[type] = data;
    },
  },
  actions: {
    parseClinic({ commit }, data) {
      return APIAi.parseClinic(data).then((response) => {
        if (response && response.data) {
          commit('SET_DATA', { type: 'parsedClinic', data: response.data });
        }
      });
    },
  },
};
