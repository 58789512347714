import ConfigCmsApi from '../configs/ConfigCmsApi';
import Cookies from "js-cookie";

const getReviews = (params) => ConfigCmsApi.get('/reviews/', {
  params,
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const getReview = (reviewId) => ConfigCmsApi.get(`/reviews/${reviewId}`, {
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const getReviewsAnalytics = (params) => ConfigCmsApi.get('/reviews/analytic', {
  params,
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const setReviewApprove = (reviewId) => ConfigCmsApi.request({
  url: `/reviews/${reviewId}/approve`,
  method: 'POST',
  headers: {
    Authorization: Cookies.get('adminToken'),
  }
});

const updateReview = ({ data, reviewId }) => ConfigCmsApi.put(`/reviews/${reviewId}`, data, {
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});


export default {
  getReviews,
  getReview,
  getReviewsAnalytics,
  setReviewApprove,
  updateReview,
};
