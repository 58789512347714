<template>
  <nav aria-label="breadcrumb" role="navigation">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a target="_blank" href="https://admin.bookimed.com">CMS</a></li>
      <template v-if="$route.meta.breadcrumbs">
        <li class="breadcrumb-item" v-for="(breadcrumb, index) in breadcrumbs"
            :key="index">
          <router-link :to="{ name: breadcrumb.routeName }">{{breadcrumb.title}}</router-link>
        </li>
      </template>
      <li class="breadcrumb-item breadcrumb-item__current" aria-current="page">{{$route.meta.title}}</li>
    </ol>
  </nav>
</template>

<script>
  export default {
    computed: {
      breadcrumbs() {
        return this.$route.meta.breadcrumbs.map((breadcrumb) => ({
          routeName: breadcrumb.routeName,
          title: breadcrumb.title,
        }));
      },
    }
  }
</script>
