import Cookies from 'js-cookie';
import ConfigCmsApi from '../configs/ConfigCmsApi';

const getCountries = (params) => ConfigCmsApi.get('/data/countries', {
  params,
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const getCountry = (countryId) => ConfigCmsApi.get(`/data/countries/${countryId}`, {
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const getDomains = () => ConfigCmsApi.get('/data/domains', {
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const getCities = (params) => ConfigCmsApi.get('/data/cities', {
  params,
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const getCity = (cityId) => ConfigCmsApi.get(`/data/cities/${cityId}`, {
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});


const getIllnesses = (params) => ConfigCmsApi.get('/data/illnesses', {
  params,
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const getOperations = (params) => ConfigCmsApi.get('/data/operations', {
  params,
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const getDiagnostics = (params) => ConfigCmsApi.get('/data/diagnostics', {
  params,
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const getIllnessKinds = (params) => ConfigCmsApi.get('/data/illness-kinds', {
  params,
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const getEntity = ({ entitiesString, entityId }) => ConfigCmsApi.get(`/data/${entitiesString}/${entityId}`, {
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const updateEntityDetails = ({ entityId, data, entitiesString }) => ConfigCmsApi.put(`/data/${entitiesString}/${entityId}/details`, data, {
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const saveEntity = ({ data, entitiesString }) => ConfigCmsApi.post(`/data/${entitiesString}`, data, {
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const linkOrUnlinkConnection = ({ entityId, methodType, data, entity, entitiesString }) => ConfigCmsApi.put(`/data/${entitiesString}/${entityId}/${methodType}/${entity}`, data, {
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const getEntityTexts = ({ lang, entitiesString, entityId }) => ConfigCmsApi.get(`/texts/${lang}/${entitiesString}/${entityId}`, {
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const saveEntityTexts = ({ lang, entitiesString, entityId, data }) => ConfigCmsApi.put(`/texts/${lang}/${entitiesString}/${entityId}`, data, {
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const getIllnessesByProcedure = ({ procedure, procedureId }) => ConfigCmsApi.get(`/data/illnesses/${procedure}/${procedureId}`, {
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const getEntityTextsConnection = ({ lang, entitiesString, entityId, forEntity, forEntityId }) => ConfigCmsApi.get(`/texts/entity-texts/${lang}/${entitiesString}/${entityId}/${forEntity}/${forEntityId}`, {
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const saveEntityTextsConnection = ({ lang, entitiesString, entityId, forEntity, forEntityId, data }) => ConfigCmsApi.put(`/texts/entity-texts/${lang}/${entitiesString}/${entityId}/${forEntity}/${forEntityId}`, data, {
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const translateEntityTextsConnection = ({ lang, entitiesString, entityId, forEntity, forEntityId, alias, data }) => ConfigCmsApi.post(`texts/entity-texts/${lang}/${entitiesString}/${entityId}/${forEntity}/${forEntityId}/${alias}/translate`, data, {
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const uploadDoctorCertificate = ({ doctorId, file, name }) => {
  const bodyFormData = new FormData();
  bodyFormData.append('file', file);
  bodyFormData.append('name', name);

  return ConfigCmsApi.post(`/doctors/${doctorId}/certificate`, bodyFormData, {
    headers: {
      Authorization: Cookies.get('adminToken'),
    },
  });
};

const getUnlinkEntities = () => ConfigCmsApi.get(`/clinics/unlink-entities`, {
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const changeUnlinkStatus = ({ clinicId, entityType, status }) => ConfigCmsApi.request({
  url: `/clinics/unlink-entities/${clinicId}/type/${entityType}/${status}`,
  method: 'PUT',
  headers: {
    Authorization: Cookies.get('adminToken'),
  }
});

const getClinicsMedia = (params) => ConfigCmsApi.get('/clinics/media', {
  params,
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const getClinicsMediaItem = (mediaId) => ConfigCmsApi.get(`/clinics/media/${mediaId}`, {
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const saveClinicMedia = ({ id, data }) => ConfigCmsApi.post(`/clinics/media/${id}`, data, {
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const putClinicMedia = ({ id, data }) => ConfigCmsApi.put(`/clinics/media/${id}`, data, {
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const saveClinicMediaStatus = ({ mediaId, status }) => ConfigCmsApi.request({
  url: `/clinics/media/${mediaId}/${status}`,
  method: 'post',
  headers: {
    Authorization: Cookies.get('adminToken'),
  }
});

export default {
  getCountries,
  getCountry,
  getDomains,
  getCities,
  getCity,
  getIllnesses,
  getOperations,
  getIllnessKinds,
  getDiagnostics,
  getEntity,
  updateEntityDetails,
  saveEntity,
  linkOrUnlinkConnection,
  getEntityTexts,
  saveEntityTexts,
  getIllnessesByProcedure,
  getEntityTextsConnection,
  saveEntityTextsConnection,
  translateEntityTextsConnection,
  uploadDoctorCertificate,
  getUnlinkEntities,
  changeUnlinkStatus,
  getClinicsMedia,
  saveClinicMedia,
  saveClinicMediaStatus,
  getClinicsMediaItem,
  putClinicMedia,
};
