import APIReviews from '@/api/modules/reviews';

const initialState = {
  reviews: [],
  review: {
    text: {},
  },
  analytic: [],
  reviewsMeta: {},
};

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    SET_DATA(state, { type, data }) {
      state[type] = data;
    },
  },
  actions: {
    getReviews({ commit }, params) {
      return APIReviews.getReviews(params).then((response) => {
        if (response && response.data && response.data.reviews) {
          commit('SET_DATA', { type: 'reviews', data: response.data.reviews });
          commit('SET_DATA', { type: 'reviewsMeta', data: response.data.meta });
        }
      });
    },
    getReview({ commit }, reviewId) {
      return APIReviews.getReview(reviewId).then((response) => {
        if (response && response.data && response.data.review) {
          let clientResponse = response.data.review.client_response;

          if (!clientResponse) {
            clientResponse = {
              what_like_text: {},
              what_not_like_text: {},
              preview_to_review_text: {},
            }
          }

          commit('SET_DATA', { type: 'review', data: { ...response.data.review, client_response: clientResponse } });
        }
      });
    },
    getReviewsAnalytics({ commit }, params) {
      return APIReviews.getReviewsAnalytics(params).then((response) => {
        if (response && response.data && response.data.analytic) {
          commit('SET_DATA', { type: 'analytic', data: response.data.analytic });
        }
      });
    },
    updateReview({ commit }, data) {
      return APIReviews.updateReview(data);
    },
    setReviewApprove({ commit }, reviewId) {
      return APIReviews.setReviewApprove(reviewId);
    },
  },
};
