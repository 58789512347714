import ConfigAI from '../configs/ConfigAI';
import Cookies from "js-cookie";

const parseClinic = (data) => ConfigAI.post('/clinic-parser-playground/', data, {
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

export default {
  parseClinic,
};
