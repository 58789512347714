import APIOffersClusters from '@/api/modules/offers-clusters';

const initialState = {
  agentsNamesOffers: [],
  modelsNamesOffers: [],
  agentDataByNameOffers: {},
};


export default {
  namespaced: true,
  state: initialState,
  mutations: {
    SET_DATA(state, { type, data }) {
      state[type] = data;
    },
  },
  actions: {
    getAgentsNamesOffers({ commit }) {
      return APIOffersClusters.getAgentsNamesOffers().then((response) => {
        if (response && response.data) {
          commit('SET_DATA', { type: 'agentsNamesOffers', data: response.data });
        }
      });
    },
    getModelsNameOffers({ commit }) {
      return APIOffersClusters.getModelsNameOffers().then((response) => {
        if (response && response.data) {
          commit('SET_DATA', { type: 'modelsNamesOffers', data: response.data });
        }
      });
    },
    getAgentDataByNameOffers({ commit }, params) {
      return APIOffersClusters.getAgentDataByNameOffers(params).then((response) => {
        if (response && response.data) {
          commit('SET_DATA', { type: 'agentDataByNameOffers', data: response.data });
        }
      });
    },
    updateAgentOffers({ commit }, data) {
      return APIOffersClusters.updateAgentOffers(data);
    }
  },
};
