import App from "@/App.vue";
import AdminLayout from "@/layout/AdminLayout.vue";
import AuthLayout from "@/layout/AuthLayout.vue";

// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";

// Admin pages
const Doctor = () => import(/* webpackChunkName: "dashboard" */"@/pages/Doctor.vue");
const Doctors = () => import(/* webpackChunkName: "common" */ "@/pages/Doctors.vue");
const Clinics = () => import(/* webpackChunkName: "common" */ "@/pages/Clinics.vue");
const Clinic = () => import(/* webpackChunkName: "common" */ "@/pages/Clinic.vue");
const ClinicEntityTexts = () => import(/* webpackChunkName: "common" */ "@/pages/ClinicEntityTexts.vue");
const Operations = () => import(/* webpackChunkName: "common" */ "@/pages/Operations.vue");
const Operation = () => import(/* webpackChunkName: "common" */ "@/pages/Operation.vue");
const Illnesses = () => import(/* webpackChunkName: "common" */ "@/pages/Illnesses.vue");
const Illness = () => import(/* webpackChunkName: "common" */ "@/pages/Illness.vue");
const Diagnostics = () => import(/* webpackChunkName: "common" */ "@/pages/Diagnostics.vue");
const Diagnostic = () => import(/* webpackChunkName: "common" */ "@/pages/Diagnostic.vue");
const IllnessKinds = () => import(/* webpackChunkName: "common" */ "@/pages/Illness-kinds.vue");
const IllnessKind = () => import(/* webpackChunkName: "common" */ "@/pages/Illness-kind.vue");
const Cities = () => import(/* webpackChunkName: "common" */ "@/pages/Cities.vue");
const City = () => import(/* webpackChunkName: "common" */ "@/pages/City.vue");
const Countries = () => import(/* webpackChunkName: "common" */ "@/pages/Countries.vue");
const Country = () => import(/* webpackChunkName: "common" */ "@/pages/Country.vue");
const Reviews = () => import(/* webpackChunkName: "common" */ "@/pages/Reviews.vue");
const Review = () => import(/* webpackChunkName: "common" */ "@/pages/Review.vue");
const UnlinkRequests = () => import(/* webpackChunkName: "common" */ "@/pages/UnlinkRequests.vue");
const HelpCenterList = () => import(/* webpackChunkName: "common" */ "@/pages/HelpCenter/HelpCenterList.vue");
const ClinicParser = () => import(/* webpackChunkName: "common" */ "@/pages/ClinicParser.vue");
const Agents = () => import(/* webpackChunkName: "common" */ "@/pages/Agents.vue");

const routes = [
  {
    path: "/",
    component: App,
    redirect: { name: 'Doctors' },
    children: [
      {
        path: 'admin',
        props: true,
        component: AdminLayout,
        redirect: { name: 'Doctors' },
        children: [
          {
            path: "doctors",
            props: true,
            name: "Doctors",
            meta: {
              title: 'Doctors',
            },
            component: Doctors
          },
          {
            path: "clinics-parser",
            props: true,
            name: "ClinicParser",
            meta: {
              title: 'Clinic parser',
            },
            component: ClinicParser
          },
          {
            path: "doctor/:doctorId",
            props: true,
            name: "Doctor",
            meta: {
              title: 'Doctor',
              breadcrumbs: [{
                routeName: 'Doctors',
                title: 'Doctors'
              }]
            },
            component: Doctor
          },
          {
            path: "clinics",
            props: true,
            name: "Clinics",
            meta: {
              title: 'Clinics',
            },
            component: Clinics
          },
          {
            path: "clinic/:clinicId",
            props: true,
            name: "Clinic",
            meta: {
              title: 'Clinic',
              breadcrumbs: [{
                routeName: 'Clinics',
                title: 'Clinics'
              }]
            },
            component: Clinic
          },
          {
            path: "/clinic/:clinicId/entity-texts/:entity/:entityId",
            props: true,
            name: "ClinicEntityTexts",
            meta: {
              title: 'Clinic entity texts',
              breadcrumbs: [
                {
                  routeName: 'Clinics',
                  title: 'Clinics'
                },
                {
                  routeName: 'Clinic',
                  title: 'Clinic'
                }
              ]
            },
            component: ClinicEntityTexts
          },
          {
            path: "operations",
            props: true,
            name: "Operations",
            meta: {
              title: 'Operations',
            },
            component: Operations
          },
          {
            path: "operation/:entityId",
            props: true,
            name: "operation",
            meta: {
              title: 'Operation',
              breadcrumbs: [{
                routeName: 'Operations',
                title: 'Operations'
              }]
            },
            component: Operation
          },
          {
            path: "cities",
            props: true,
            name: 'Cities',
            meta: {
              title: 'Cities',
            },
            component: Cities
          },
          {
            path: "city/:entityId",
            props: true,
            name: "city",
            meta: {
              title: 'City',
              breadcrumbs: [{
                routeName: 'Cities',
                title: 'Cities'
              }]
            },
            component: City
          },
          {
            path: "countries",
            props: true,
            name: 'countries',
            meta: {
              title: 'Countries',
            },
            component: Countries
          },
          {
            path: "country/:entityId",
            props: true,
            name: "country",
            meta: {
              title: 'Country',
              breadcrumbs: [{
                routeName: 'countries',
                title: 'Countries'
              }]
            },
            component: Country
          },
          {
            path: "illnesses",
            props: true,
            name: "Illnesses",
            meta: {
              title: 'Illnesses',
            },
            component: Illnesses
          },
          {
            path: "illness/:entityId",
            props: true,
            name: "illness",
            meta: {
              title: 'Illness',
              breadcrumbs: [{
                routeName: 'Illnesses',
                title: 'Illnesses'
              }]
            },
            component: Illness
          },
          {
            path: "illness-kinds",
            props: true,
            name: "illness-kinds",
            meta: {
              title: 'Specialties',
            },
            component: IllnessKinds
          },
          {
            path: "illness-kind/:entityId",
            props: true,
            name: "illness-kind",
            meta: {
              title: 'Specialty',
              breadcrumbs: [{
                routeName: 'illness-kinds',
                title: 'Specialties'
              }]
            },
            component: IllnessKind
          },
          {
            path: "diagnostics",
            props: true,
            name: "Diagnostics",
            meta: {
              title: 'Diagnostics',
            },
            component: Diagnostics
          },
          {
            path: "diagnostic/:entityId",
            props: true,
            name: "diagnostic",
            meta: {
              title: 'Diagnostic',
              breadcrumbs: [{
                routeName: 'Diagnostics',
                title: 'Diagnostics'
              }]
            },
            component: Diagnostic
          },
          {
            path: "reviews",
            props: true,
            name: "Reviews",
            meta: {
              title: 'Reviews',
            },
            component: Reviews
          },
          {
            path: "review/:reviewId",
            props: true,
            name: "Review",
            meta: {
              title: 'Review',
              breadcrumbs: [{
                routeName: 'Reviews',
                title: 'Reviews'
              }]
            },
            component: Review
          },
          {
            path: "unlink-requests",
            props: true,
            name: "Unlink requests",
            meta: {
              title: 'Unlink requests',
            },
            component: UnlinkRequests
          },
          {
            path: "help-center/list",
            props: true,
            name: "list",
            meta: {
              title: 'Help center',
            },
            component: HelpCenterList
          },
          {
            path: "bot/agents",
            props: true,
            name: "Agents",
            meta: {
              title: 'Agents',
            },
            component: Agents
          },
        ]
      },
      {
        path: 'auth',
        props: true,
        component: AuthLayout,
        redirect: { name: 'Login' },
        children: [
          {
            path: '/auth/login',
            name: 'Login',
            props: true,
            meta: {
              title: 'Login',
            },
            component: () => import('@/pages/Login.vue'),
          },
        ]
      },
    ]
  },
  { path: '*',
    redirect: { name: 'Doctors' },
  },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
