<template>
  <div class="content">
    <Breadcrumbs/>
    <fade-transition :duration="100" mode="out-in">
      <!-- your content here -->
      <router-view></router-view>
    </fade-transition>
  </div>
</template>
<script>
  import {FadeTransition} from 'vue2-transitions';
  import Breadcrumbs from './dashboard/Breadcrumbs';

  export default {
    components: {
      FadeTransition,
      Breadcrumbs,
    }
  };
</script>
