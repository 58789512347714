import ConfigAuth from '../configs/ConfigAuth';
import Cookies from "js-cookie";

const userAuthorization = (data) => ConfigAuth.post('/auth/login', data);

const refreshToken = () => ConfigAuth.request({
  url: '/auth/refresh',
  method: 'POST',
  headers: {
    Authorization: Cookies.get('adminToken'),
  }
});


export default {
  userAuthorization,
  refreshToken,
};
