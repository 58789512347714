<template>
  <div class="wrapper white-content">
    <side-bar :sidebar-links="sidebarLinks"> </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>
      <admin-content @click.native="toggleSidebar">
      </admin-content>
    </div>
  </div>
</template>
<script>

import TopNavbar from "./TopNavbar.vue";
import AdminContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
export default {
  computed: {
    sidebarLinks() {
      return [
        { path: '/admin/doctors', name: 'Doctors', icon: 'tim-icons icon-single-02' },
        { path: '/admin/clinics', name: 'Clinics', icon: 'tim-icons icon-bank' },
        { path: '/admin/illness-kinds', name: 'Specialties', icon: 'fa fa-heartbeat' },
        { path: '/admin/illnesses', name: 'Illnesses', icon: 'fa fa-disease' },
        { path: '/admin/operations', name: 'Operations', icon: 'fa fa-user-md' },
        { path: '/admin/diagnostics', name: 'Diagnostics', icon: 'fa fa-stethoscope' },
        { path: '/admin/countries', name: 'Countries', icon: 'fa fa-city' },
        { path: '/admin/cities', name: 'Cities', icon: 'fa fa-city' },
        { path: '/admin/reviews', name: 'Reviews', icon: 'tim-icons icon-chat-33' },
        { path: '/admin/unlink-requests', name: 'Unlink requests', icon: 'tim-icons icon-chat-33' },
        { path: '/admin/help-center/list', name: 'Help center', icon: 'tim-icons icon-paper' },
      ];
    }
  },
  components: {
    TopNavbar,
    AdminContent,
    MobileMenu
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    }
  }
};
</script>
