import ConfigChatApi from '../configs/ConfigChatApi';
import Cookies from "js-cookie";

const getAgentsNames  = () => ConfigChatApi.get('/prompts/agents_names ', {
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const getModelsName  = () => ConfigChatApi.get('/prompts/models_name', {
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const getAgentDataByName   = (params) => ConfigChatApi.get('/prompts/agent_data', {
  params,
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

const updateAgent = (data) => ConfigChatApi.post('/prompts/update_agent ', data, {
  headers: {
    Authorization: Cookies.get('adminToken'),
  },
});

export default {
  getAgentsNames,
  getModelsName,
  getAgentDataByName,
  updateAgent,
};
