import APIDoctors from '@/api/modules/doctors';

const initialState = {
  doctors: [],
  doctorsMeta: {},
  doctor: {},
  doctorSpecialities: [],
  doctorDegrees: [],
  defaultDoctorAliasTexts: [
    { inputType: 'text', alias: 'info_fact' },
    { inputType: 'wysiwyg', alias: 'short_description' },
    { inputType: 'wysiwyg', alias: 'description' },
    { inputType: 'text', alias: 'text_with_video_presentation' },
    { inputType: 'text', alias: 'txt_advisory' },
  ]
};

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    SET_DATA(state, { type, data }) {
      state[type] = data;
    },
  },
  actions: {
    getDoctors({ commit }, params) {
      return APIDoctors.getDoctors(params).then((response) => {
        if (response && response.data && response.data.doctors) {
          commit('SET_DATA', { type: 'doctors', data: response.data.doctors });
          commit('SET_DATA', { type: 'doctorsMeta', data: response.data.meta });
        }
      });
    },
    getDoctor({ commit }, doctorId) {
      return APIDoctors.getDoctor(doctorId).then((response) => {
        if (response && response.data) {
          commit('SET_DATA', { type: 'doctor', data: response.data });
        }
      });
    },
    getDoctorSpecialities({ commit }) {
      return APIDoctors.getDoctorSpecialities().then((response) => {
        if (response && response.data) {
          commit('SET_DATA', { type: 'doctorSpecialities', data: response.data });
        }
      });
    },
    getDoctorDegrees({ commit }) {
      return APIDoctors.getDoctorDegrees().then((response) => {
        if (response && response.data) {
          commit('SET_DATA', { type: 'doctorDegrees', data: response.data });
        }
      });
    },
    updateDoctor({ commit }, data) {
      return APIDoctors.updateDoctor(data);
    },
    updateDoctorsPhoto({ commit }, params) {
      return APIDoctors.updateDoctorsPhoto(params);
    },
    deleteDoctorsPhoto({ commit }, doctorId) {
      return APIDoctors.deleteDoctorsPhoto(doctorId);
    }
  },
};
